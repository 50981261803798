import * as React from 'react';
import { navigate } from 'gatsby'
import Layout from '../../components/Layout';
import Footer from '../../components/Footer';
import SingleBlog from '../../modules/blog/SingleBlog';
import Blogs from '../../modules/blog/posts'
import { Helmet } from 'react-helmet';

const isBrowser = typeof window !== "undefined"

const BlogView = ({}) => {
  const id = 'low-code-no-code-prototyping';
  
  if (!Blogs[id]) {
    navigate('/');
    return '';
  }

  const post = Blogs[id];
  if (!post) {
    return '';
  }
  const data = post?.data;

  return (
    <Layout url={`https://zegates.com/blog/${id}`} description={data.description} title={data?.title} metaImage={`https://zegates.com${data.image}`}>
      <SingleBlog id={id} />
      <Footer />
    </Layout>
  );
};

export default BlogView;
